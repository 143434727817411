import React from 'react';
import * as Styled from '../headerWithParagraph/headerWithParagraphStyles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const headerWithParagraphAndNumberLinks = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeadingH4>{children}</Styled.HeadingH4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <Styled.OrderListItem>{children}</Styled.OrderListItem>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItemNumber>{children}</Styled.ListItemNumber>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <>
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
      )}
      {sectionData?.subText?.trim() && (
        <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
      )}
      {sectionData?.contentDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.contentDetails?.raw),
          optionsMainStyle
        )}
    </>
  );
};

export default headerWithParagraphAndNumberLinks;
